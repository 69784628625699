import React, { useState ,  useEffect} from 'react';
import { saveAs } from 'file-saver';
import Layout from '../Layout/Layout'
import { Link } from 'react-router-dom';
import Sidebar from '../Pages/Sidebar';




const generatePassword = (start, end, minLength, maxLength, includeUpper, includeLower, includeNumbers, includeSpecial, avoidAmbiguous) => {
    const lowerCharset = "abcdefghijklmnopqrstuvwxyz";
    const upperCharset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numberCharset = "0123456789";
    const specialCharset = "!@#$%^&*()_+[]{}|;:,.<>?/";
    const ambiguousCharset = "IOl1";
    
    let charset = "";
    if (includeLower) charset += lowerCharset;
    if (includeUpper) charset += upperCharset;
    if (includeNumbers) charset += numberCharset;
    if (includeSpecial) charset += specialCharset;
    
    if (avoidAmbiguous) {
      charset = charset.split('').filter(char => !ambiguousCharset.includes(char)).join('');
    }
  
    if (minLength > maxLength) {
      return "Minimum length cannot be greater than maximum length.";
    }
    
    const length = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
  
    let password = start;
    const remainingLength = length - (start.length + end.length);
  
    if (remainingLength < 0) {
      return "Total length is too short for the given start and end.";
    }
  
    for (let i = 0; i < remainingLength; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
  
    return password + end;
  };

const PasswordGenerator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [minLength, setMinLength] = useState(8);
    const [maxLength, setMaxLength] = useState(16);
    const [includeUpper, setIncludeUpper] = useState(true);
    const [includeLower, setIncludeLower] = useState(true);
    const [includeNumbers, setIncludeNumbers] = useState(true);
    const [includeSpecial, setIncludeSpecial] = useState(false);
    const [passwords, setPasswords] = useState([]);
  
    const handleGeneratePasswords = () => {
      const newPasswords = [];
      for (let i = 0; i < 10; i++) { // Generate 10 passwords by default
        newPasswords.push({ Password: generatePassword(start, end, minLength, maxLength, includeUpper, includeLower, includeNumbers, includeSpecial) });
      }
      setPasswords(newPasswords);
    };
  
  
    
 
  
    const handleExportToTXT = () => {
      const txt = passwords.map(p => p.Password).join('\n');
      const blob = new Blob([txt], { type: 'text/plain;charset=utf-8;' });
      saveAs(blob, 'passwords.txt');
    };
  
   
    
  return (
    <Layout 
    
    pageTitle="Excel Password Generator | ExcelsGenerator"
metaDescription="Generate secure passwords for Excel files with our Excel Password Generator. Customize password length, characters, and ensure strong protection for your data."
metaKeywords="Excel password generator, secure password, password protection, generate password for Excel"
canonicalUrl="https://excelsgenerator.com/excel-password-generator"
robotsContent="index, follow"
ogTitle="Excel Password Generator | ExcelsGenerator"
ogDescription="Generate secure passwords for Excel files with our Excel Password Generator. Customize password length, characters, and ensure strong protection for your data."
ogUrl="https://excelsgenerator.com/excel-password-generator"
ogImage="https://excelsgenerator.com/img/logo.png"
schemaData={{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "url": "https://excelsgenerator.com/excel-password-generator",
  "name": "Excel Password Generator",
  "description": "Generate secure passwords for Excel files with our Excel Password Generator. Customize password length, characters, and ensure strong protection for your data.",
  "publisher": {
    "@type": "Organization",
    "name": "ExcelsGenerator",
    "logo": {
      "@type": "ImageObject",
      "url": "https://excelsgenerator.com/img/logo.png"
    }
  }
}}

    
    >
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-8 main-section'>
          <div className='container'>
          <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/" style={{textDecoration:'none', color:'black'}}>Home</Link></li>
                    <li className="breadcrumb-item"><Link to="/all-tools" style={{textDecoration:'none' , color:'black'}}>Tools</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Excel Password  Generator</li>
                </ol>
        </nav>
          </div>
          <h1 className='text-center'>Excel Password Generator</h1>
          <div className='container text-center'>
            <div className='row'>
              <div className='col-md-12'>
              <label htmlFor='startString' className='form-label label'>
          <b>Starting String:</b>
          <span className='text-danger'>*</span>
         
        </label>
        <input
        className='form-control'
            type="text"
            id='input'
            value={start}
            onChange={(e) => setStart(e.target.value)}
            placeholder='Enter Starting String'
          />
              </div>
            </div>
            <div className='row input-row'>
              <div className='col-md-12'>
              <label htmlFor='EndingString' className='form-label label'> <b>Ending String:</b> <span className='text-danger'>*</span> </label>
        <input
        className='form-control'
            type="text"
            id='input'
            value={end}
            onChange={(e) => setEnd(e.target.value)}
            placeholder='Enter Ending String'
          />
              </div>
            </div>
             
             <div className='row input-row'>
              <div className='col-md-12'>
                      <label htmlFor='MinimunLength' className='form-label label'>
                  <b>Minimum Length:</b>
                <span className='text-danger'>*</span>
                </label>
                <input
                    type="number"
                    className='form-control'
                    id='input'
                    value={minLength}
                    onChange={(e) => setMinLength(Number(e.target.value))}
                    min="1"
                  />
              </div>
             </div>

             <div className='row input-row'>
              <div className='col-md-12'>
              <label htmlFor='Maximum' className='form-label label'><b>Maximum Length:</b><span className='text-danger'>*</span> </label>
          <input
            type="number"
            className='form-control'
            id='date'
            value={maxLength}
            onChange={(e) => setMaxLength(Number(e.target.value))}
            min="1"
          />
              </div>
             </div>

             <div className='row input-row'>
              <div className='col-md-3'>
              <input
                type="checkbox"
                checked={includeUpper}
                onChange={(e) => setIncludeUpper(e.target.checked)}
              />
               <label> Include Uppercase Letters: </label>
              </div>
              <div className='col-md-3'>
              <input
            type="checkbox"
            checked={includeLower}
            onChange={(e) => setIncludeLower(e.target.checked)}
          />
          <label>
                  Include Lowercase Letters:
                
                </label>
              </div>
              <div className='col-md-3'>
              <input
            type="checkbox"
            checked={includeNumbers}
            onChange={(e) => setIncludeNumbers(e.target.checked)}
          />
           
        <label>
          Include Numbers:
       
        </label>
              </div>
              <div className='col-md-3'>
              <input
            type="checkbox"
            checked={includeSpecial}
            onChange={(e) => setIncludeSpecial(e.target.checked)}
          />
              <label>
          Include Special Characters:
        
        </label>
              </div>
             </div>
            
            <div className='row input-row'>
              <div className='col-md-12'>
              <button className="cta-button btn btn-lg" onClick={handleGeneratePasswords} >
          Generate Passwords
        </button>
      
     



              </div>
            </div>
          </div>
           
      {passwords.length > 0 && (
      <div className='container'>
        <div className='row'>
          <div className='table-responsive'>
          <table className="table table-striped">
          <thead>
            <tr>
              <th>Password</th>
            </tr>
          </thead>
          <tbody>
            {passwords.map((entry, index) => (
              <tr key={index}>
                <td>{entry.Password}</td>
              </tr>
            ))}
            <button className="btn btn-success"  style={{marginLeft:"2vh"}} onClick={handleExportToTXT}>
          Export to TXT
        </button>
          </tbody>
        </table>
          </div>
        </div>
      </div>
      )}

      <div className='container' style={{marginTop:"4vh"}}>
        <div className='row'>
          <hr></hr>
          <h3 className='text-center text-black' > About Excel Password Generator</h3>
          <hr></hr>
          <p style={{fontFamily:'sans-serif'}}>An Excel password generator is a highly useful tool designed to create strong, random passwords that can be used to protect Excel files or other sensitive data. With the increasing importance of digital security, a robust password is essential for safeguarding personal and professional information stored in Excel spreadsheets. A password generator typically allows users to define the length, complexity, and type of characters (letters, numbers, symbols) that should be included in the password. This ensures that passwords are not easily guessable and comply with best practices in security, making them more resistant to brute force attacks or hacking attempts.</p>
          <p style={{fontFamily:'sans-serif'}}>In addition to providing security, an Excel password generator simplifies the process of creating passwords, removing the need to manually think of one. Since Excel files are often used for financial data, business analytics, and personal records, having a strong password is crucial to ensure that unauthorized users cannot access or alter the contents. Modern password generators often include features such as customizable settings, allowing users to adjust the parameters to meet specific requirements for different levels of security.</p>

          <p>Another significant benefit of using an Excel password generator is that it promotes the use of unique passwords. Instead of reusing the same password across multiple files, which poses a significant security risk, the generator can produce distinct, high-entropy passwords for each file. This is particularly important for organizations or individuals who handle multiple Excel sheets with sensitive data. By integrating this tool into their workflow, users can protect their documents more effectively, minimizing the risk of data breaches and maintaining the confidentiality of their information.</p>


          <h4><b>Advantages of Excel Password Generator</b></h4>
          <p style={{fontFamily:'sans-serif'}}>One of the key advantages of an Excel password generator is that it saves time and effort by quickly generating passwords that meet high-security standards. Users don’t need to manually create passwords, which reduces the chance of using predictable or repetitive patterns.</p>
          <p style={{fontFamily:'sans-serif'}}> Additionally, it eliminates the tendency to reuse the same password across multiple files, ensuring each file has a unique and secure password. This not only strengthens data protection but also reduces the chances of a widespread breach if one password is compromised. Moreover, an Excel password generator supports long and complex passwords, which are far more difficult for hackers to crack through brute-force attacks.</p>

          <h4><b>Features of Excel Password Generator</b></h4>
          <p style={{fontFamily:'sans-serif'}}>An Excel password generator typically offers a range of customizable features, allowing users to specify the length and complexity of the password. For instance, you can select whether to include uppercase and lowercase letters, numbers, and special characters to meet specific security needs. Some advanced password generators even allow users to set specific rules for creating passwords, such as excluding similar-looking characters (e.g., ‘O’ and ‘0’), enhancing usability without compromising security. </p>

          <p style={{fontFamily:'sans-serif'}}>Additionally, many password generators are designed to integrate seamlessly with Excel workflows, making the process of securing files quick and efficient. These features provide flexibility while ensuring strong, unique passwords that align with best practices in cybersecurity.</p>

           <h5><b>Summary</b></h5>
          <p style={{fontFamily:"sans-serif"}}>In summary, an Excel password generator is an invaluable tool for enhancing the security of Excel files by automating the creation of complex and secure passwords. It ensures that passwords meet security standards and provides a simple solution to manage the increasing demands of data protection in today's digital world.</p>

        </div>
      </div>
        </div>
        <div className='col-md-3'>
         <Sidebar/>
        </div>
      </div>
    </div>
      <div className="password-generator-container">
     
   
    </div>

    </Layout>
  )
}

export default PasswordGenerator
