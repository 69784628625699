// src/RandomDataGenerator.js

import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { faker } from '@faker-js/faker'; // Import faker
import Layout from '../Layout/Layout'
import { Link } from 'react-router-dom';
import Sidebar from '../Pages/Sidebar';

const RandomDataGenerator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [numberOfRecords, setNumberOfRecords] = useState(0);
  const [dataList, setDataList] = useState([]);
  const [dataTypes, setDataTypes] = useState({
    name: true,
    email: true,
    phone: true,
    address: true,
    company: false,
    job: false,
    product: false,
    creditCard: false,
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  //eslint-disable-next-line
  const [chunkSize, setChunkSize] = useState(1000); // Number of records to generate at a time

  useEffect(() => {
    const savedDataTypes = JSON.parse(localStorage.getItem('dataTypes'));
    if (savedDataTypes) {
      setDataTypes(savedDataTypes);
    }
  }, []);

  const handleDataTypeChange = (type) => {
    setDataTypes((prev) => {
      const updatedDataTypes = { ...prev, [type]: !prev[type] };
      localStorage.setItem('dataTypes', JSON.stringify(updatedDataTypes));
      return updatedDataTypes;
    });
  };

  const generateRandomData = async () => {
    if (numberOfRecords === 0) {
      setError('Please Enter Number of Records.');
      return;
    }
    
    if (Object.values(dataTypes).every((val) => !val)) {
      setError('Please select at least one data type to generate.');
      return;
    }

    setError('');
    setLoading(true);
    setDataList([]); // Clear previous data
    const data = [];

    for (let i = 0; i < numberOfRecords; i += chunkSize) {
      const chunk = [];
      for (let j = 0; j < chunkSize && (i + j) < numberOfRecords; j++) {
        const record = { id: i + j + 1 };
        if (dataTypes.name) record.name = faker.person.fullName(); // Updated here
        if (dataTypes.email) record.email = faker.internet.email();
        if (dataTypes.phone) record.phone = faker.phone.number(); // Updated here
        if (dataTypes.address) record.address = faker.location.streetAddress(); // Updated here
        if (dataTypes.company) record.company = faker.company.name(); // Updated here
        if (dataTypes.job) record.job = faker.person.jobTitle(); // Updated here
      
        if (dataTypes.product) record.product = faker.commerce.productName();
        if (dataTypes.creditCard) record.creditCard = faker.finance.creditCardNumber();
        chunk.push(record);
      }
      data.push(...chunk);
      setDataList((prevData) => [...prevData, ...chunk]); // Append new data to existing data
      await new Promise((resolve) => setTimeout(resolve, 50)); // Simulate async operation
    }

    setLoading(false);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(dataList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Random Data');
    XLSX.writeFile(workbook, 'Random_Data.xlsx');
  };

  return (
    <Layout 
    
    pageTitle="Excel Random Data Generator | ExcelsGenerator"
metaDescription="Generate random data directly in Excel with our Excel Random Data Generator. Easily create custom sets of random data for your spreadsheets."
metaKeywords="Excel random data generator, random data in Excel, generate random numbers, Excel tool"
canonicalUrl="https://excelsgenerator.com/excel-random-data-generator"
robotsContent="index, follow"
ogTitle="Excel Random Data Generator | ExcelsGenerator"
ogDescription="Generate random data directly in Excel with our Excel Random Data Generator. Easily create custom sets of random data for your spreadsheets."
ogUrl="https://excelsgenerator.com/excel-random-data-generator"
ogImage="https://excelsgenerator.com/img/logo.png"
schemaData={{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "url": "https://excelsgenerator.com/excel-random-data-generator",
  "name": "Excel Random Data Generator",
  "description": "Generate random data directly in Excel with our Excel Random Data Generator. Easily create custom sets of random data for your spreadsheets.",
  "publisher": {
    "@type": "Organization",
    "name": "ExcelsGenerator",
    "logo": {
      "@type": "ImageObject",
      "url": "https://excelsgenerator.com/img/logo.png"
    }
  }
}}

    
    >
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-8 main-section'>
          <div className='container'>
          <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/" style={{textDecoration:'none', color:'black'}}>Home</Link></li>
                    <li className="breadcrumb-item"><Link to="/all-tools" style={{textDecoration:'none' , color:'black'}}>All Tools</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Excel  Random Data Generator</li>
                </ol>
        </nav>
        <h1 className='text-center'>Excel  Random Data Generator</h1>
        <div className='container text-center'>
          <div className='row'>
            <div className='col-md-12'>
            <label htmlFor='NumberOfRecords' className='form-label label'>
            <b>Number of Records:</b>
            <span className='text-danger'>*</span>
           
          </label>
          <input
              type="number"
              value={numberOfRecords}
              id='input'
              className='form-control'
              onChange={(e) => setNumberOfRecords(e.target.value)}
              placeholder='Enter Number of Records'
            />
            </div>
          </div>
          <div className='row input-row'>
            <div className='col-md-12'>

            <div style={styles.checkboxContainer}>
          {Object.keys(dataTypes).map((type) => (
            <label key={type} style={styles.checkboxLabel}>
              <input
                type="checkbox"
                checked={dataTypes[type]}
                onChange={() => handleDataTypeChange(type)}
              />
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </label>
          ))}
        </div>
           
            </div>
           
          </div>

          {error && <p style={{ color: 'red' }}>{error}</p>}

          <div className='row input-row'>
            <div className='col-md-12'>
            <button className='cta-button btn btn-lg' onClick={generateRandomData} >
          Generate Random Data
        </button>
        {loading && <p>Loading... Please wait.</p>}
      
            </div>
          </div>
        </div>

          </div>
                    
          <div className='container' style={{marginTop:'4vh'}}>
            <div className='row'>
              <div className='table-responsive'>
              <table className="table table-bordered">
                    <thead>
                      <tr>
                        {Object.keys(dataTypes).map(
                          (type) => dataTypes[type] && <th key={type}>{type.charAt(0).toUpperCase() + type.slice(1)}</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {dataList.map((item) => (
                        <tr key={item.id}>
                          {Object.entries(item)
                            .filter(([key]) => key !== 'id') // Exclude ID from display
                            .map(([key, value]) => (
                              <td key={key}>{value}</td>
                            ))}
                        </tr>
                      ))}
                    </tbody>
              
                  </table>
                  <button style={{marginTop:"2vh"}} className='btn btn-success' onClick={exportToExcel} disabled={dataList.length === 0} >
                    Export to Excel
                  </button>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row'>
              <hr></hr>
              <h3>About Excel Random Data Generator</h3>
              <hr></hr>
              <p style={{fontFamily:'sans-serif'}}>In today's data-driven world, businesses and individuals frequently need large datasets for testing, analysis, and training machine learning models. However, creating this data manually can be time-consuming and error-prone. This is where an Excel Random Data Generator becomes invaluable. By automating the process of generating large datasets with randomized values, this tool saves time, ensures variety, and enhances the overall accuracy of testing environments.</p>
              <h4><b>What Is an Excel Random Data Generator?</b></h4>
              <p style={{fontFamily:'sans-serif'}}>An Excel Random Data Generator is a tool designed to automatically create large sets of random data based on user-defined parameters. This data can include common fields such as names, emails, phone numbers, addresses, and even company names or credit card numbers. The data can be exported directly to an Excel file, making it easy to manipulate, analyze, or import into other systems.</p>
              <p style={{fontFamily:'sans-serif'}}>These generators are particularly useful for:
              Software developers who need sample data for testing applications.
              Data scientists who need to train models with large, varied datasets. Educators who want to provide students with practice datasets.
              Business analysts who require quick access to synthetic data for various types of analysis.
              </p>

              <h4><b>Key Features of a Random Data Generator</b></h4>
              <p>Users can specify the types of data they want to generate, including names, emails, phone numbers, addresses, and more. For example, users can generate a list of random names along with corresponding email addresses, or create complex datasets containing company details and product information.</p>
              <p>Users can define the number of records to generate, ranging from just a few entries to thousands, based on their specific needs.</p>

              <p>Once the random data is generated, users can export it directly into an Excel file format (XLSX), allowing for easy access and further manipulation in Microsoft Excel.</p>
              <p>The random data generator creates diverse and realistic data, ensuring that testing environments are as close to real-world scenarios as possible. For instance, phone numbers can be formatted according to local standards, and names can come from a variety of cultural backgrounds.</p>

              <h4><b>Advantages of Using an Excel Random Data Generator</b></h4>

              <div style={{fontFamily:'sans-serif'}}>
              <p>Instead of manually inputting data, users can generate thousands of records within minutes. This speeds up the development and testing processes, allowing users to focus on other tasks.</p>
              <p>A random data generator introduces variety into datasets, ensuring that the data is sufficiently randomized to test edge cases, improve robustness, and ensure that applications can handle diverse inputs.</p>
              <p>Manually creating large datasets often introduces human errors, such as duplicate entries or formatting inconsistencies. By automating data creation, these issues are minimized, leading to cleaner datasets.</p>
              <p>Developers can use this tool to generate test data for software applications that handle user information, such as forms, databases, and CRM systems. The ability to simulate realistic datasets ensures that applications are properly stress-tested before going live.</p>
              </div>

              <h4><b>How to Use an Excel Random Data Generator</b></h4>
              <div style={{fontFamily:'sans-serif'}}>
              <p> Choose the types of data you want to generate, such as name, email, phone number, address, or job title.</p>
              <p> Input the number of records you want to generate. For example, if you need 1,000 rows of data, simply input the number 1000.</p>
              <p>Click the "Generate" button, and the tool will create the random data based on the selected fields and number of records.</p>
              <p> Once the data is generated, you can export it into an Excel file with the click of a button. The data will be organized in a tabular format, making it easy to use for analysis or testing.</p>
              </div>

              <h5><b>Conclusion</b></h5>
            <div style={{fontFamily:"sans-serif"}}>
              <p>An Excel Random Data Generator is a powerful and time-saving tool for anyone who needs to generate large datasets quickly. Whether you're a software developer, data scientist, business analyst, or educator, this tool can make your work more efficient and error-free. With features like customizable data fields, seamless Excel export, and error-free data generation, the tool helps streamline processes and ensure accurate testing or analysis. If you work with data on a regular basis, integrating an Excel Random Data Generator into your workflow can significantly improve productivity and the quality of your work.</p>
            </div>
            </div>
          </div>
        </div>
        <div className='col-md-3'>
          <Sidebar/>
        </div>
      </div>
    </div>
     
       {/* Table instead of List */}
 


      
  
    </Layout>
  );
};

const styles = {
  container: {
    padding: '20px',
    maxWidth: '500px',
    margin: 'auto',
    border: '1px solid #ccc',
    borderRadius: '8px',
    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
    backgroundColor: '#f9f9f9',
  },
  inputGroup: {
    marginBottom: '10px',
  },
  input: {
    marginLeft: '10px',
  },
  button: {
    marginTop: '10px',
    padding: '10px 15px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  checkboxContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)', // 4 checkboxes per row
    gap: '10px',
  },
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center',
  },
};

export default RandomDataGenerator;
