import React, { useState, useRef , useEffect } from 'react';
import html2canvas from 'html2canvas';
import Layout from '../Layout/Layout';
import { Link } from 'react-router-dom';
import Sidebar from '../Pages/Sidebar'

const BillGenerator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [currentStep, setCurrentStep] = useState(0);
  const [clientName, setClientName] = useState('');
  const [clientAddress, setClientAddress] = useState('');
  const [clientContact, setClientContact] = useState('');
  const [description, setDescription] = useState('');
  const [quantity, setQuantity] = useState('');
  const [price, setPrice] = useState('');
  const [taxRate, setTaxRate] = useState('');
  const [billItems, setBillItems] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [billImage, setBillImage] = useState(null);
  const billRef = useRef();

  const addItem = () => {
    if (description && quantity && price) {
      const itemQuantity = parseInt(quantity);
      const itemPrice = parseFloat(price);
      const itemTotal = itemQuantity * itemPrice;
      const taxAmount = itemTotal * (taxRate / 100);
      const totalWithTax = itemTotal + taxAmount;

      const newItem = {
        description,
        quantity: itemQuantity,
        price: itemPrice,
        total: totalWithTax,
      };

      setBillItems([...billItems, newItem]);

      // Clear inputs
      setDescription('');
      setQuantity('');
      setPrice('');
    } else {
      alert('Please fill out all fields for the bill items.');
    }
  };

 

  const captureBillAsImage = () => {
    html2canvas(billRef.current).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      setBillImage(imgData);
    });
  };

  const downloadImage = () => {
    const link = document.createElement('a');
    link.href = billImage;
    link.download = 'professional-bill.png';
    link.click();
  };

  const nextStep = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <Layout 
    pageTitle="Excel Bill Generator | ExcelsGenerator"
  metaDescription="Easily generate professional Excel bills with our Excel Bill Generator. Customize fields and download your invoice in Excel format."
  metaKeywords="Excel bill generator, invoice generator, custom Excel invoice, generate bill in Excel"
  canonicalUrl="https://excelsgenerator.com/excel-bill-generator"
  robotsContent="index, follow"
  ogTitle="Excel Bill Generator | ExcelsGenerator"
  ogDescription="Easily generate professional Excel bills with our Excel Bill Generator. Customize fields and download your invoice in Excel format."
  ogUrl="https://excelsgenerator.com/excel-bill-generator"
  ogImage="https://excelsgenerator.com/img/logo.png"
  schemaData={{
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": "https://excelsgenerator.com/excel-bill-generator",
    "name": "Excel Bill Generator",
    "description": "Easily generate professional Excel bills with our Excel Bill Generator. Customize fields and download your invoice in Excel format.",
    "publisher": {
      "@type": "Organization",
      "name": "ExcelsGenerator",
      "logo": {
        "@type": "ImageObject",
        "url": "https://excelsgenerator.com/img/logo.png"
      }
    }
  }}

    >
      <div className="container-fluid">
        <div className='row'>
          <div className='col-md-1'></div>
          <div className='col-md-8 main-section'>
          <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/all-tools" style={{ textDecoration: 'none', color: 'black' }}>
                      All Tools
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Excel Bill Generator
                  </li>
                </ol>
                </nav>
            <div className='container text-center'>
              <h1 className='text-center' style={{color:'black'}}>Excel Bill Generator</h1>

              <div className='row'>
                <div className='col-md-12'>
                  {currentStep === 0 && (
                    <div>
                      <h4 style={{marginTop:"2vh"}}>Company Information</h4>
                      <div className="form-group">
                        <label htmlFor='CompanyName' className='form-label label'>
                          <b>Company Name:</b><span className='text-danger'>*</span>
                        </label>
                        <input
                          type="text"
                          id='date'
                          className="form-control"
                          value={companyName}
                          placeholder='Enter Company Name'
                          onChange={(e) => setCompanyName(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor='CompanyAddress' className='form-label label'>
                          <b>Company Address:</b><span className='text-danger'>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id='input'
                          value={companyAddress}
                          onChange={(e) => setCompanyAddress(e.target.value)}
                          placeholder='Enter Company Address'
                        />
                      </div>
                   
                      <button className="btn btn-primary" onClick={nextStep}>Next</button>
                    </div>
                  )}
                </div>
              </div>

              {/* Step 1 - Client Information */}
              <div className='row'>
                <div className='col-md-12'>
                  {currentStep === 1 && (
                    <div>
                      <h4 style={{marginTop:"2vh"}}>Client Information</h4>
                      <div className="form-group">
                        <label htmlFor='clientName' className='form-label label'>Client Name:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={clientName}
                          id='date'
                          onChange={(e) => setClientName(e.target.value)}
                          placeholder='Enter Client Name'
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor='ClientAddress' className='form-label label'>
                          <b>Client Address:</b><span className='text-danger'>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id='input'
                          value={clientAddress}
                          onChange={(e) => setClientAddress(e.target.value)}
                          placeholder='Enter Client Address'
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor='ClientContact' className='form-label label'>
                          <b>Client Contact:</b><span className='text-danger'>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id='date'
                          value={clientContact}
                          onChange={(e) => setClientContact(e.target.value)}
                          placeholder='Enter Client Contact Email Or Number'
                        />
                      </div>
                      <button className="btn btn-secondary" onClick={prevStep}>Previous</button>
                      <button style={{marginLeft:"2vh"}} className="btn btn-primary" onClick={nextStep}>Next</button>
                    </div>
                  )}
                </div>
              </div>

              {/* Step 2 - Billing Items */}
              <div className='row'>
                <div className='col-md-12'>
                  {currentStep === 2 && (
                    <div>
                      <h4 style={{marginTop:"2vh"}}>Billing Items</h4>
                      <div className="form-group">
                        <label htmlFor='taxRate' className='form-label label'>
                          <b>Tax Rate (%):</b><span className='text-danger'>*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id='input'
                          value={taxRate}
                          onChange={(e) => setTaxRate(e.target.value)}
                          placeholder='Enter Tax Rate'
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor='Description' className='form-label label'>
                          <b>Description:</b><span className='text-danger'>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id='date'
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          placeholder='Enter Description of Item'
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor='Quantity' className='form-label label'>
                          <b>Quantity:</b><span className='text-danger'>*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id='input'
                          value={quantity}
                          onChange={(e) => setQuantity(e.target.value)}
                          placeholder='Enter Quantity Of Items'
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor='Price' className='form-label label'>
                          <b>Price:</b><span className='text-danger'>*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id='date'
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                          placeholder='Enter Price Per Item'
                        />
                      </div>

                      <button className="btn btn-secondary" onClick={prevStep}>Previous</button>
                      <button style={{marginLeft:"2vh"}} className="cta-button btn btn-lg mt-4" onClick={addItem}>Add Item</button>
                      <button className="btn btn-success" style={{marginLeft:"2vh"}} onClick={captureBillAsImage}>Generate Bill</button>
                    </div>
                  )}
                </div>
              </div>

              

              {/* Bill Preview */}
              <div style={{marginTop:"4vh"}} ref={billRef}>
                {billImage ? (
                  <>
                    <h4>Preview:</h4>
                    <img src={billImage} alt="Bill Preview" style={{width: '100%', maxHeight: '500px'}} />
                    <button  style={{marginTop:"2vh"}} className="btn btn-primary" onClick={downloadImage}>Download Bill</button>
                  </>
                ) : (
                  <div className="preview-container">
                    {/* Your Bill design */}
                    <div className='row'>
                      <div className='col-md-6'>
                      <p><b>Client Name: </b>{clientName}</p>
                      <p><b>Client Address: </b>{clientAddress}</p>
                      <p><b>Client Contact :</b>{clientContact}</p>
                      </div>
                      <div className='col-md-6'>
                        <p><b>Company Name: </b>{companyName}</p>
                        <p><b>Company Address: </b>{companyAddress}</p>
                      </div>

                    </div>

                    <h4>Billing</h4>
                   
                    {/* Add more details here */}
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Description</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {billItems.map((item, index) => (
                          <tr key={index}>
                            <td>{item.description}</td>
                            <td>{item.quantity}</td>
                            <td>{item.price.toFixed(2)}</td>
                            <td>{item.total.toFixed(2)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
            <div className='container'>
              <div className='row' style={{marginTop:'50px'}}>
                <hr></hr>
                <h3 className='text-center text-black'><b>About Excel Bill Generator</b></h3>
                <hr></hr>
                <p style={{fontFamily:"sans-serif"}}>
                In today's digital world, businesses and individuals alike are moving towards efficient ways of managing financial transactions. One such tool that is widely used is the Excel Bill Generator. This simple, yet powerful tool allows users to create invoices, manage billing, and keep track of financial data without the need for expensive software. Whether you're a small business owner, freelancer, or even an individual managing household expenses, an Excel Bill Generator can be a game-changer.
                </p>
                <h4><b>What is an Excel Bill Generator?</b></h4>
                <p style={{fontFamily:'sans-serif'}}> 
                An Excel Bill Generator is essentially a spreadsheet template designed to generate bills or invoices. It leverages Excel’s built-in formulas and features, making it easy to calculate totals, taxes, discounts, and more. This tool is particularly beneficial because it allows for automation of billing processes, reducing manual entry errors and speeding up the invoicing process.
                </p>
                <h4><b>Key Features of an Excel Bill Generator</b></h4>
              <div style={{fontFamily:"sans-serif"}}>
              <p> The primary benefit of using an Excel Bill Generator is its ability to automate calculations. You can input basic details like item price, quantity, and tax rate, and Excel will automatically calculate the subtotal, tax amount, and total amount due.</p>
              <p>Excel provides customizable templates, allowing businesses to create professional invoices tailored to their needs. You can modify the layout, add your company logo, and personalize the design to suit your branding.</p>
              <p> Excel spreadsheets make it easy to track billing history. You can save copies of invoices for future reference, organize them by client, date, or payment status, and quickly retrieve past invoices for auditing or reporting.</p>
              <p>With Excel’s formula functionality, you can set up automatic tax and discount calculations, which is especially useful if you operate in multiple tax jurisdictions or offer discounts to customers.</p>
              <p>Once your invoice is ready, you can easily print it out or save it as a PDF to email to your clients. Excel allows for flexibility in how you deliver your bills.</p>
              <p>Excel files are easily compatible with other financial tools and accounting software. You can export the data for further analysis or import it into accounting systems for bookkeeping purposes.</p>
              </div>

             <h5><b>Why Use an Excel Bill Generator?</b></h5>
             <div style={{fontFamily:"sans-serif"}}>
              <p>Excel is a widely available tool that often comes pre-installed on computers, making it a cost-effective option compared to specialized invoicing software.</p>
              <p>For users familiar with Excel, creating a bill generator is relatively simple. Excel’s user-friendly interface and extensive online tutorials make it easy to design and use even if you’re not an expert in spreadsheets.</p>
              <p>Unlike some rigid invoicing software, Excel allows you to tailor the bill generation process to your specific needs. You can adjust the layout, add new fields, or create custom reports based on your business’s unique requirements.</p>
              <p></p>
             </div>
             <h5><b>How to Create an Excel Bill Generator</b></h5>
             <div>
              <p>Creating an Excel Bill Generator is straightforward and involves the following steps:</p>
                      <li>Define sections for important details such as invoice number, date, client name, description of goods/services, quantity, unit price, tax, and total amount.</li>
                      <li>Use Excel Formulas: Input formulas for automated calculations. For example: <br></br>
        Subtotal: =SUM(Unit_Price * Quantity) <br></br> Tax: =Subtotal * Tax_Rate <br></br> Total: =Subtotal + Tax</li>
        <p>Format the document to make it look professional. This includes adding your company logo, contact information, and payment terms.</p>
      <p>Once the generator is set up, test it to ensure all calculations are working correctly. Afterward, save it as a template, so you can use it repeatedly without having to re-create it from scratch.</p>
             </div>
              </div>
            </div>
          </div>
          <div className='col-md-3'>
            <Sidebar/>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BillGenerator;
