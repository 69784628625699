import React from 'react'
import { Link } from 'react-router-dom'

const Sidebar = () => {
  return (
    <div>
      <div className='ads'>
        <p className='text-center'>ads</p>
      </div>

<div className='text'>
<h3 className='text-center' style={{color:'white', paddingTop:'0.5rem'}}>Important Tools</h3>
</div>
<div className='tools-list'>
 <ul>
  <li> <Link to='/excel-chart-generator' target="_blank">Excel Chart Generator</Link></li>
  <li><Link to='/excel-random-date-generator' target="_blank"> Excel Random Date Generator </Link></li>
  <li> <Link to='/excel-random-number-generator' target="_blank">Excel Random Number Generator</Link></li>
  <li><Link to='/excel-formula-generator' target="_blank">Excel Formula Generator</Link></li>
  <li><Link to='/excel-function-generator' target="_blank">Excel Function Generator</Link></li>
  <li><Link to="/excel-invoice-number-generator" target="_blank">Excel invoice Number Generator</Link></li>
  <li><Link to="/excel-password-generator" target="_blank">Excel Password Generator</Link></li>
  <li><Link to="/excel-qr-code-generator" target="_blank">Excel QR Code Generator</Link></li>
  <li><Link to="/excel-budget-calculator" target="_blank">Excel Budget Generator</Link></li>
  <li><Link to="/excel-bill-generator" target="_blank">Excel Bill Generator</Link></li>
  <li><Link to="/excel-bingo-card-generator" target="_blank">Excel Bingo Card Generator</Link></li>
  <li><Link to="/excel-epoch-time-generator" target="_blank">Excel Epoch Time Generator</Link></li>
  <li><Link to="/excel-sku-generator" target="_blank">Excel Sku Generator</Link></li>
  <li><Link to="/google-sheets-formula-generator" target="_blank">Google Sheets Formula Generator</Link></li>
  <li><Link to="/excel-random-data-generator" target="_blank">Excel Random Data Generator</Link></li>
   
  
   
   
 </ul>
</div>
    </div>
  )
}

export default Sidebar
