import React from 'react';
import { Link } from 'react-router-dom';
function Footer() {
  return (
    <footer className="footer py-4">
      <div className="container text-center">
        <p>&copy; 2024 Excel Generators . All rights reserved.</p>
        <nav>
          <ul className="list-inline">
            <li className="list-inline-item"><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li className="list-inline-item"><Link to="/terms-and-conditions">Terms and Condition</Link></li>
            <li className="list-inline-item"><Link to="/disclaimer">Disclaimer</Link></li>
          </ul>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;
