import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import Layout from '../Layout/Layout';
import './main.css'
import './Responsive.css'
import Sidebar from '../Pages/Sidebar';
// import FirstSidebar from '../Pages/FirstSidebar';

const BudgetCalculator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [income, setIncome] = useState('');
  const [expenses, setExpenses] = useState('');
  const [savings, setSavings] = useState(0);
  const [remaining, setRemaining] = useState(0);
  const [budgets, setBudgets] = useState([]);
  const [error, setError] = useState(''); 
  const [isCalculated, setIsCalculated] = useState(false);

  // Load budgets from local storage when the component mounts
  useEffect(() => {
    const savedBudgets = JSON.parse(localStorage.getItem('budgets')) || [];
    setBudgets(savedBudgets);
  }, []);

  const calculateBudget = () => {
    if (!income || !expenses) {
      setError('Please enter both income and expenses');
      return;
    }
    
    setError(''); 

    const totalIncome = parseFloat(income) || 0;
    const totalExpenses = parseFloat(expenses) || 0;
    const totalSavings = totalIncome - totalExpenses;

    const newBudget = {
      income: totalIncome,
      expenses: totalExpenses,
      savings: totalSavings,
      remaining: totalSavings > 0 ? totalSavings : 0,
    };

    const updatedBudgets = [...budgets, newBudget];
    setBudgets(updatedBudgets);
    localStorage.setItem('budgets', JSON.stringify(updatedBudgets));

    setIncome('');
    setExpenses('');
    setSavings(totalSavings);
    setRemaining(totalSavings > 0 ? totalSavings : 0);

    setIsCalculated(true);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(budgets);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Budgets');
    XLSX.writeFile(workbook, 'Budgets.xlsx');
  };

  // Delete a budget by index
  const deleteBudget = (index) => {
    const updatedBudgets = budgets.filter((_, i) => i !== index);
    setBudgets(updatedBudgets);
    localStorage.setItem('budgets', JSON.stringify(updatedBudgets));
  };

  return (
    <Layout 
    pageTitle="Excel Budget Calculator | ExcelsGenerator"
metaDescription="Easily create and manage budgets with our Excel Budget Calculator. Customize fields and track your expenses in Excel format."
metaKeywords="Excel budget calculator, budget management, Excel expense tracker, budget generator"
canonicalUrl="https://excelsgenerator.com/excel-budget-calculator"
robotsContent="index, follow"
ogTitle="Excel Budget Calculator | ExcelsGenerator"
ogDescription="Easily create and manage budgets with our Excel Budget Calculator. Customize fields and track your expenses in Excel format."
ogUrl="https://excelsgenerator.com/excel-budget-calculator"
ogImage="https://excelsgenerator.com/img/logo.png"
schemaData={{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "url": "https://excelsgenerator.com/excel-budget-calculator",
  "name": "Excel Budget Calculator",
  "description": "Easily create and manage budgets with our Excel Budget Calculator. Customize fields and track your expenses in Excel format.",
  "publisher": {
    "@type": "Organization",
    "name": "ExcelsGenerator",
    "logo": {
      "@type": "ImageObject",
      "url": "https://excelsgenerator.com/img/logo.png"
    }
  }
}}

    >
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-1' >
             {/* <FirstSidebar /> */}
          </div>
          <div className='col-md-8 main-section'>
            <div className='container'>
              
              <h1 className='text-center'>Excel Budget Calculator</h1>

              <div className='container text-center'>
                <div className='row input-row'>
                  <div className='col-md-12'>
                    <label htmlFor='monthlyIncome' className='form-label label'>
                      <b>Monthly Income:</b> <span className='text-danger'>*</span>
                    </label>
                    <input
                      type="number"
                      className='form-control'
                      id='income'
                      value={income}
                      onChange={(e) => setIncome(e.target.value)}
                      placeholder='Enter your Monthly Income'
                    />
                  </div>
                </div>

                <div className='row input-row'>
                  <div className='col-md-12'>
                    <label htmlFor='expenses' className='form-label label'>
                      <b>Monthly Expenses:</b> <span className='text-danger'>*</span>
                    </label>
                    <input
                      type="number"
                      className='form-control'
                      id='expenses'
                      value={expenses}
                      onChange={(e) => setExpenses(e.target.value)}
                      placeholder='Enter Your Monthly Expenses'
                    />
                  </div>
                </div>

                {error && ( 
                  <div className='row'>
                    <div className='col-md-12'>
                      <p className='text-danger'>{error}</p>
                    </div>
                  </div>
                )}

                <div className='row input-row'>
                  <div className='col-md-12'>
                    <button className='cta-button btn btn-lg mt-4' onClick={calculateBudget}>Calculate</button>
                  </div>
                </div>
              </div>

              {isCalculated && (
          <div className='container'>
        
          <hr></hr>
          <h2>Result :</h2> <br></br>
          <div className='row'>
            <div className='table-responsive'>
              <table className='table'>
                <tbody>
                  <tr>
                    <th scope='col'>Total Savings</th>
                    <td> ${savings}</td>
                  </tr>
                  <tr>
                    <th scope='col'>Remaining</th>
                    <td>${remaining}</td>
                  </tr>
                </tbody>
              </table>
              <button id='annualInterestRate' className='btn btn-success' onClick={exportToExcel}>Export to Excel</button>
            </div>
            
          </div>
          </div>
        )}

        {isCalculated && (
          <div className='container'>
            <hr></hr>
            <h3>Previous Budgets</h3> <br></br>
            <div className='row'>
              <div className='table-responsive'>
              
        <table className='table'>
          <thead>
            <tr>
              <th style={{ border: '1px solid black', padding: '8px' }}>Income</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Expenses</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Savings</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Remaining</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Action</th> {/* New Action Column */}
            </tr>
          </thead>
          <tbody>
            {budgets.map((budget, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid black', padding: '8px' }}>${budget.income}</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>${budget.expenses}</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>${budget.savings}</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>${budget.remaining}</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>
                  <button className='btn btn-danger' onClick={() => deleteBudget(index)}>Delete</button> {/* Delete Button */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
              </div>
            </div>
          </div>
        )}

        <div className='container'>
          <div className='row' style={{marginTop:"50px"}}>
          <hr></hr>
          <h3 className='text-center text-black'>About Excel Budget Calculator Tools</h3>
          <hr></hr>

          <p style={{fontFamily:'sans-serif'}}>Managing personal finances efficiently is crucial in today's fast-paced world. A Budget Calculator can be an invaluable tool for individuals to track their income, expenses, and savings, ensuring better financial planning. In this article, we'll explore how to build a Budget Calculator tool using React.js, allowing users to input their monthly income and expenses, calculate savings, export data to Excel, and even delete past budgets.</p>

          <h4>Overview of Features</h4>
          <p>The Budget Calculator built with React.js offers a range of features that make it easy for users to stay on top of their finances:</p>
          <div>
            <ul>
              <li><b>Income and Expense Input:</b> Users can input their monthly income and expenses into the calculator. This is the foundation for calculating savings and the remaining balance after expenses are deducted.</li>
              <li><b>Savings Calculation:</b> Once users input their income and expenses, the calculator computes the savings dynamically. It subtracts the expenses from income, displaying the remaining amount if there’s a surplus or showing zero if expenses exceed income.</li>
              <li><b>Export Data to Excel:</b> A standout feature of this tool is the ability to export the budget data to an Excel file. With a single click, users can download their income, expenses, and savings data for offline review or further analysis.</li>
              <li><b>Delete Old Budgets:</b> Users can delete any old or irrelevant budget entries. This functionality allows them to manage their budget history by keeping only the relevant entries.</li>
            </ul>

          </div>
          <h5>Why Build a Budget Calculator?</h5>
           <p>Personal budgeting is essential for financial stability, but not everyone uses or has access to sophisticated tools like Microsoft Excel or specialized financial software. A simple Budget Calculator that is accessible online allows users to quickly input data and view their savings without needing complex software. Moreover, the Excel export feature ensures that they can further analyze or save their data offline.</p>
         
         <h4>Key Benefits of This Tool</h4><br></br>
         <div style={{fontFamily:'sans-serif'}}>
          <ul>
            <li><b>Simplicity:</b> The Budget Calculator offers a simple interface, where users can quickly input their monthly income and expenses, making it user-friendly even for those without technical expertise.</li>
            <li><b>Efficiency:</b> It calculates the remaining savings dynamically, so users don’t need to perform manual calculations. This not only saves time but also minimizes errors.</li>
            <li><b>Accessibility:</b> By saving budgets in local storage, the tool provides easy access to users without needing to register or log in, ensuring privacy and convenience.</li>
            <li><b>Versatility with Excel Export: </b>While the online calculator is powerful on its own, users who want to perform further analysis can export the data to Excel. This ensures that the tool is useful for basic financial planning as well as more detailed analysis.</li>
            <li><b>Data Management:</b> The option to delete past budgets keeps the interface clean and ensures that users only see relevant data. This simple yet effective feature makes managing multiple budgets easier.</li>
          </ul>
         </div>
         <h5>Use Cases</h5>
         <div>
          <ul>
            <li><b>Personal Finance:</b> Individuals who want a simple and effective way to manage their monthly budget can use this tool to track their savings.</li>
            <li><b>Small Businesses:</b> Small businesses can quickly input income and expenses to manage their cash flow and export it to Excel for future reference or analysis.</li>
            <li><b>Educational Purpose:</b> This tool can be useful for educational purposes, teaching students how to manage budgets effectively without the complexity of larger financial software.</li>
          </ul>
         </div>
        
          </div>
        </div>

        
            </div>
          </div>
          <div className='col-md-3'>
            <Sidebar/>
          </div>
        </div>

      


      </div>
    </Layout>
  );
};

export default BudgetCalculator;
