import React, { useEffect } from 'react';
import Layout from '../Layout/Layout';
import { Link } from 'react-router-dom';

const Tool = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout
      pageTitle="Excel Tools | ExcelsGenerator"
      metaDescription="Explore our comprehensive suite of Excel tools designed to enhance productivity and simplify your tasks. From bill generators to budget calculators, find the perfect tool for your needs."
      metaKeywords="Excel tools, Excel generators, productivity tools, online Excel calculators, budget calculators"
      canonicalUrl="https://excelsgenerator.com/tools"
      robotsContent="index, follow"
      ogTitle="Excel Tools | ExcelsGenerator"
      ogDescription="Discover a variety of Excel tools at ExcelsGenerator, crafted to help you work smarter and achieve more in your Excel projects."
      ogUrl="https://excelsgenerator.com/tools"
      ogImage="https://excelsgenerator.com/img/logo.png"
      schemaData={{
        "@context": "https://schema.org",
        "@type": "WebPage",
        "url": "https://excelsgenerator.com/tools",
        "name": "Excel Tools",
        "description": "Explore our comprehensive suite of Excel tools designed to enhance productivity and simplify your tasks. From bill generators to budget calculators, find the perfect tool for your needs.",
        "publisher": {
          "@type": "Organization",
          "name": "ExcelsGenerator",
          "logo": {
            "@type": "ImageObject",
            "url": "https://excelsgenerator.com/img/logo.png",
          },
        },
      }}
    >

<div className="image-aboutus-banner" >
   <div className="container">
 
    <div className="row">
        <div className="col-md-12">
         <h1 className="lg-text text-white">All Tools</h1>
		 <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><Link to="/"  className="text-white font-weight-bold text-decoration-none">Home</Link></li>
        <li className="breadcrumb-item active text-decoration-none" aria-current="page"> All Tool</li>
      </ol>
    </nav>
       </div>
    </div>
</div>
</div>
      <div className='container my-5'>
        <h2 className='text-center mb-4'>Explore Our Excel Calculators</h2>

        <div className='row mt-5'>
          <div className='col-md-3'>
          <div className='card' style={{width:"18rem"}}>
            <div className='card-body'>
             <h5 className='card-title'><b>
             Excel Random Data Generator
             </b></h5>    
             <p className='card-text'>
             Generate  data directly in Excel with our Excel  Data Generator
             </p>     
             <Link to='/excel-random-data-generator'>
              <button className='btn btn-success'>Try Now</button>
                  </Link>   
             </div>
          </div>
          </div>

          <div className='col-md-3'>
          <div className='card' style={{width:"18rem"}}>
            <div className='card-body'>
             <h5 className='card-title'><b>
             Excel QR Code <br></br> Generator </b></h5>    
             <p className='card-text'>
             Generate QR codes  in Excel with our Excel QR Code Generator.
             </p>     
             <Link to='/excel-qr-code-generator'>
              <button className='btn btn-success'>Try Now</button>
                  </Link>   
             </div>
          </div>
          </div>
          <div className='col-md-3'>
          <div className='card' style={{width:"18rem"}}>
            <div className='card-body'>
             <h5 className='card-title'><b>
             Excel SKU Generator
             </b></h5>    
             <p className='card-text'>
             Easily generate unique SKU numbers in Excel with our Excel SKU Generator.
             </p>     
             <Link to='/excel-sku-generator'>
              <button className='btn btn-success'>Try Now</button>
                  </Link>   
             </div>
          </div>
          </div>
          <div className='col-md-3'>
          <div className='card' style={{width:"18rem"}}>
            <div className='card-body'>
             <h5 className='card-title'><b>
             Excel Password <br></br> Generator
             </b></h5>    
             <p className='card-text'>
             Generate passwords for Excel files with our Excel Password Generator.
             </p>     
             <Link to='/excel-password-generator'>
              <button className='btn btn-success'>Try Now</button>
                  </Link>   
             </div>
          </div>
          </div>
        </div>

        <div className='row mt-5'>
        <div className='col-md-3'>
          <div className='card' style={{width:"18rem"}}>
            <div className='card-body'>
             <h5 className='card-title'><b>
             Excel Invoice Number  <br></br> Generator
             </b></h5>    
             <p className='card-text'>
             Generate unique invoice numbers with our Excel Invoice Number Generator.
             </p>     
             <Link to='/excel-invoice-number-generator'>
              <button className='btn btn-success'>Try Now</button>
                  </Link>   
             </div>
          </div>
          </div>

          <div className='col-md-3'>
          <div className='card' style={{width:"18rem"}}>
            <div className='card-body'>
             <h5 className='card-title'><b>
             Excel Grocery List  <br></br> Generator
             </b></h5>    
             <p className='card-text'>
             Easily create and customize grocery lists with our Excel Grocery List Generator.
             </p>     
             <Link to='/excel-grocery-list-generator'>
              <button className='btn btn-success'>Try Now</button>
                  </Link>   
             </div>
          </div>
          </div>

          <div className='col-md-3'>
          <div className='card' style={{width:"18rem"}}>
            <div className='card-body'>
             <h5 className='card-title'><b>
              Sheets Formula <br></br> Generator
             </b></h5>    
             <p className='card-text'>
             Easily generate  Google Sheets formulas with our  Sheets Formula Generator.
             </p>     
             <Link to='/google-sheets-formula-generator'>
              <button className='btn btn-success'>Try Now</button>
                  </Link>   
             </div>
          </div>
          </div>

          
          <div className='col-md-3'>
          <div className='card' style={{width:"18rem"}}>
            <div className='card-body'>
             <h5 className='card-title'><b>
             Excel Function <br></br> Generator
             </b></h5>    
             <p className='card-text'>
             Create functions for various data manipulations and download them in Excel format.
             </p>     
             <Link to='/excel-function-generator'>
              <button className='btn btn-success'>Try Now</button>
                  </Link>   
             </div>
          </div>
          </div>
        </div>

        <div className='row mt-5'>
        <div className='col-md-3'>
          <div className='card' style={{width:"18rem"}}>
            <div className='card-body'>
             <h5 className='card-title'><b>
             Excel Formula  <br></br> Generator
             </b></h5>    
             <p className='card-text'>
             Generate formulas for various calculations and download them.
             </p>     
             <Link to='/excel-formula-generator'>
              <button className='btn btn-success'>Try Now</button>
                  </Link>   
             </div>
          </div>
          </div>


      
        <div className='col-md-3'>
          <div className='card' style={{width:"18rem"}}>
            <div className='card-body'>
             <h5 className='card-title'><b>
              Epoch Time <br></br> Generator
             </b></h5>    
             <p className='card-text'>
             Convert dates to epoch time with our Excel Epoch Time Generator.
             </p>     
             <Link to='/excel-epoch-time-generator'>
              <button className='btn btn-success'>Try Now</button>
                  </Link>   
             </div>
          </div>
          </div>

          <div className='col-md-3'>
          <div className='card' style={{width:"18rem"}}>
            <div className='card-body'>
             <h5 className='card-title'><b>
             Excel Random Date  <br></br> Generator
             </b></h5>    
             <p className='card-text'>
             Generate random dates in Excel with our Excel Random Date Generator.
             </p>     
             <Link to='/excel-random-date-generator'>
              <button className='btn btn-success'>Try Now</button>
                  </Link>   
             </div>
          </div>
          </div>

          <div className='col-md-3'>
          <div className='card' style={{width:"18rem"}}>
            <div className='card-body'>
             <h5 className='card-title'><b>
             Excel Bingo Card  <br></br> Generator
             </b></h5>    
             <p className='card-text'>
             Create customizable bingo cards with our Excel Bingo Card Generator.
             </p>     
             <Link to='/excel-bingo-card-generator'>
              <button className='btn btn-success'>Try Now</button>
                  </Link>   
             </div>
          </div>
          </div>
        </div>


        <div className='row mt-5'>
        <div className='col-md-3'>
          <div className='card' style={{width:"18rem"}}>
            <div className='card-body'>
             <h5 className='card-title'><b>
             Excel Bill   <br></br> Generator
             </b></h5>    
             <p className='card-text'>
             Easily generate professional Excel bills with our Excel Bill Generator.
             </p>     
             <Link to='/excel-bill-generator'>
              <button className='btn btn-success'>Try Now</button>
                  </Link>   
             </div>
          </div>
          </div>

          <div className='col-md-3'>
          <div className='card' style={{width:"18rem"}}>
            <div className='card-body'>
             <h5 className='card-title'><b>
             Excel Budget  <br></br> Calculator
             </b></h5>    
             <p className='card-text'>
             Easily create and manage budgets with our Excel Budget Calculator. 
             </p>     
             <Link to='/excel-budget-calculator'>
              <button className='btn btn-success'>Try Now</button>
                  </Link>   
             </div>
          </div>
          </div>

          <div className='col-md-3'>
          <div className='card' style={{width:"18rem"}}>
            <div className='card-body'>
             <h5 className='card-title'><b>
             Excel Chart   <br></br> Generator
             </b></h5>    
             <p className='card-text'>
             Generate custom charts in Excel with our Excel Chart Generator.
             </p>     
             <Link to='/excel-chart-generator'>
              <button className='btn btn-success'>Try Now</button>
                  </Link>   
             </div>
          </div>
          </div>

          <div className='col-md-3'>
          <div className='card' style={{width:"18rem"}}>
            <div className='card-body'>
             <h5 className='card-title'><b>
             Excel Random  Number  <br></br> Generator
             </b></h5>    
             <p className='card-text'>
             Generate Random  Number with our Excel Random Number Generator.
             </p>     
             <Link to='/excel-random-number-generator'>
              <button className='btn btn-success'>Try Now</button>
                  </Link>   
             </div>
          </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Tool;
