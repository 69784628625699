import React from 'react';
import Layout from '../Layout/Layout';
import { Link } from 'react-router-dom';
import '../Pages/About.css';

const Home = () => {
  return (
    <Layout 
      pageTitle="ExcelsGenerator | Your Ultimate Excel Tools Platform"
      metaDescription="Welcome to ExcelsGenerator, your one-stop destination for innovative and user-friendly Excel tools designed to enhance your productivity and streamline your workflow."
      metaKeywords="Excel tools, Excel generators, online Excel tools"
      canonicalUrl="https://excelsgenerator.com/"
      robotsContent="index, follow"
      ogTitle="ExcelsGenerator | Your Ultimate Excel Tools Platform"
      ogDescription="Discover ExcelsGenerator, the go-to platform for powerful Excel tools to help you work smarter and achieve more with your data."
      ogUrl="https://excelsgenerator.com/"
      ogImage="https://excelsgenerator.com/img/logo.png"
      schemaData={{
        "@context": "https://schema.org",
        "@type": "WebPage",
        "url": "https://excelsgenerator.com/",
        "name": "Home",
        "description": "Welcome to ExcelsGenerator, your one-stop destination for innovative and user-friendly Excel tools designed to enhance your productivity and streamline your workflow.",
        "publisher": {
          "@type": "Organization",
          "name": "ExcelsGenerator",
          "logo": {
            "@type": "ImageObject",
            "url": "https://excelsgenerator.com/img/logo.png"
          }
        }
      }}
    >

      <section className="main-section py-5">
        <div className="container text-center">
          <h2>Welcome to Excel Generators</h2>
          <p className="lead mt-4">
            Our Excel Generators Tool is your ultimate companion for creating custom Excel sheets with ease. 
            Whether you need to generate invoice numbers, random numbers, or any other sequence, we've got you covered.
          </p>
          <p className="mt-3">
            Explore our tools designed to automate your Excel tasks, saving you time and reducing errors. 
            Start customizing your spreadsheets like a pro today!
          </p>
          <Link to="/tools" className="cta-button btn btn-primary btn-lg mt-4">Try Tools</Link>
        </div>
      </section>

      {/* Features Section */}
      <section className="container my-5">
        <h2 className='text-center'>Why Choose Us?</h2>
        <div className='row text-center mt-4'>
          <div className='col-md-4 mb-4'>
            <div className="features">
              <div className="feature-card p-4 border rounded shadow-sm">
                <h4>Fast & Accurate</h4>
                <p>Generate complex Excel formulas with ease and precision.</p>
              </div>
            </div>
          </div>

          <div className='col-md-4 mb-4'>
            <div className="feature-card p-4 border rounded shadow-sm">
              <h4>Customizable Tools</h4>
              <p>Create personalized charts, graphs, and functions in seconds.</p>
            </div>
          </div>

          <div className='col-md-4 mb-4'>
            <div className="feature-card p-4 border rounded shadow-sm">
              <h4>User Friendly</h4>
              <p>Simple interface, designed for both beginners and professionals.</p>
            </div>
          </div>
        </div>
      </section>


      <div className='container-fluid' style={{marginTop:'40px'}}>
<p className='text-center' style={{fontSize:'30px',marginBottom:'10px'}}>Explore Our Calculators</p>
 <div className="container">
 
  <div className="row ">
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  <div className="card-body">
    <h5 className="card-title"><b>Excel Bill Generator</b></h5>
    <p className="card-text">Easily generate professional Excel bills with our Excel Bill Generator. Customize fields and download your invoice in Excel format. </p>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div class="card" style={{width:'18rem'}}>
  <div class="card-body">
    <h5 class="card-title"><b>Excel Bingo Card Generator</b></h5>
    <p class="card-text">Create customizable bingo cards in Excel format with our Excel Bingo Card Generator. Perfect for games, classrooms, or events. </p>

  </div>
</div>
    </div>
    <div className="col mt-5">
    <div class="card" style={{width:'18rem'}}>
  <div class="card-body">
    <h5 class="card-title"><b>Excel Budget Calculator</b></h5>
    <p class="card-text">Easily create and manage budgets with our Excel Budget Calculator. Customize fields and track your expenses in Excel format.</p>

  </div>
</div>
    </div>
    <div className="col mt-5">
    <div class="card" style={{width:'18rem'}}>
  <div class="card-body">
    <h5 class="card-title"><b>Excel Chart Generator</b></h5>
    <p class="card-text">Generate custom charts in Excel with our Excel Chart Generator. Input your data and create professional Excel charts instantly.</p>

  </div>
</div>
    </div>
  </div>

  {/* second rows  */}

  <div className="row mt-5">
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  <div className="card-body">
    <h5 className="card-title"><b>Excel Random Date Generator</b></h5>
    <p className="card-text">Generate random dates in Excel with our Excel Random Date Generator. Set custom date ranges and create random dates for your Excel sheets.</p>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  <div className="card-body">
    <h5 className="card-title"><b>Excel Epoch Time Generator</b></h5>
    <p className="card-text"> Convert dates to epoch time with our Excel Epoch Time Generator. Easily generate Unix timestamps in Excel for any given date and time.</p>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  <div className="card-body">
    <h5 className="card-title"><b>Excel Random Number Generator</b></h5>
    <p className="card-text">Generate random numbers quickly and easily with our Excel Random Number Generator. Customize the range and download your results in Excel format.</p>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  <div className="card-body">
    <h5 className="card-title"><b>Excel Formula Generator</b></h5>
    <p className="card-text">Create and customize Excel formulas easily with our Excel Formula Generator. Generate formulas for various calculations and download them in Excel format.</p>
  </div>
</div>
 </div>

  </div>
  
  
</div>
</div>

    </Layout>
  );
}

export default Home;
