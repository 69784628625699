// src/GroceryListGenerator.js

import React, { useState, useEffect} from 'react';
import * as XLSX from 'xlsx';
import Layout from '../Layout/Layout';
import { Link } from 'react-router-dom';
import Sidebar from '../Pages/Sidebar';

const GroceryListGenerator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [item, setItem] = useState('');
  const [quantity, setQuantity] = useState('');
  const [groceryList, setGroceryList] = useState([]);

  const addItem = () => {
    if (item && quantity) {
      const newItem = { item, quantity: parseInt(quantity) };
      setGroceryList([...groceryList, newItem]);
      setItem('');
      setQuantity('');
    } else {
      alert('Please enter both item and quantity.');
    }
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(groceryList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Grocery List');
    XLSX.writeFile(workbook, 'GroceryList.xlsx');
  };

  return (

<Layout 


pageTitle="Excel Grocery List Generator | ExcelsGenerator"
metaDescription="Easily create and customize grocery lists in Excel with our Excel Grocery List Generator. Organize your grocery shopping efficiently and download the list in Excel format."
metaKeywords="Excel grocery list generator, custom grocery list in Excel, generate grocery list, Excel shopping list"
canonicalUrl="https://excelsgenerator.com/excel-grocery-list-generator"
robotsContent="index, follow"
ogTitle="Excel Grocery List Generator | ExcelsGenerator"
ogDescription="Easily create and customize grocery lists in Excel with our Excel Grocery List Generator. Organize your grocery shopping efficiently and download the list in Excel format."
ogUrl="https://excelsgenerator.com/excel-grocery-list-generator"
ogImage="https://excelsgenerator.com/img/logo.png"
schemaData={{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "url": "https://excelsgenerator.com/excel-grocery-list-generator",
  "name": "Excel Grocery List Generator",
  "description": "Easily create and customize grocery lists in Excel with our Excel Grocery List Generator. Organize your grocery shopping efficiently and download the list in Excel format.",
  "publisher": {
    "@type": "Organization",
    "name": "ExcelsGenerator",
    "logo": {
      "@type": "ImageObject",
      "url": "https://excelsgenerator.com/img/logo.png"
    }
  }
}}


> 

<div className='container-fluid'>
  <div className='row'>
    <div className='col-md-1'>

    </div>
    <div className='col-md-8 main-section'>
      <div className='container'>
      <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/" style={{textDecoration:'none', color:'black'}}>Home</Link></li>
                    <li className="breadcrumb-item"><Link to="/all-tools" style={{textDecoration:'none' , color:'black'}}>All Tools</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Excel Grocery list Generator</li>
                </ol>
        </nav>
        <h1 className='text-center'>Excel Grocery list Generator</h1>

        <div className='container text-center'>
          <div className='row'>
            <div className='col-md-12'>
            <label  htmlFor='Items' className='form-label label'> <b>Item:</b> <span className='text-danger'>*</span> </label>
        <input
            type="text"
            className='form-control'
            id='input'
            value={item}
            onChange={(e) => setItem(e.target.value)}
            placeholder='Enter Items'
          />
            </div>
          </div>

          <div className='row input-row'>
            <div className='col-md-12'>
            <label htmlFor='quantity' className='form-label label'> <b>Quantity:</b> <span className='text-danger'>*</span> </label>
        <input
            type="number"
            className='form-control'
            id='date'
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            placeholder='Enter Items Quantity'
          />

            </div>
          </div>

          <div className='row input-row'>
            <div className='col-md-12'>
            <button  className='cta-button btn btn-lg' onClick={addItem}>Add Item</button>
            </div>
          </div>
        </div>

      </div>

       {groceryList.length === 1  && (
        <>
          <div className='container' style={{marginTop:'3vh'}}>
        <h3>Your Grocery List</h3>
          <ul>
        {groceryList.map((grocery, index) => (
          <li key={index}>
            {grocery.item} - {grocery.quantity}
          </li>
        ))}
      </ul>
      <button className='btn btn-success' onClick={exportToExcel} disabled={groceryList.length === 0}>
        Export to Excel
      </button>
          </div>
        </>
       )}

       <div className='container'>
        <div className='row'>
          <hr></hr>
          <h3>About Excel Your Grocery List Generator</h3>
          <hr></hr>
          <p style={{fontFamily:'sans-serif'}}>
          An Excel Grocery List Generator is a handy tool designed to simplify the process of creating, organizing, and managing grocery lists. For individuals, families, or businesses (such as restaurants) that need to keep track of grocery shopping, this tool provides a structured and efficient way to list out items needed for regular or special shopping trips. The Excel-based tool can automatically generate a categorized and customizable list, ensuring that no essential items are missed and allowing for better planning.
          </p>
          <h4><b>Key Features:</b></h4>
         <div style={{fontFamily:'sans-serif'}}>
         <p>The generator allows users to categorize items based on sections typically found in grocery stores, such as produce, dairy, meats, beverages, household supplies, etc. This makes shopping more efficient as items are grouped together, reducing time spent moving between different aisles.</p>
          <p>Users can add specific items they need and customize them with details like quantity, brand preferences, or specific store locations. This makes the list more personalized and relevant to individual shopping needs.</p>
          <p>Some versions of the generator may suggest quantities based on previous entries or predefined values. For example, if you always buy 2 gallons of milk, the tool can automatically set the quantity for future lists.</p>
          <p>Excel templates for grocery list generation are often pre-designed with formulas and sections that make it easy for users to just plug in their requirements. The tool can also accommodate weekly or monthly shopping needs by saving past lists for future reference.</p>
          <p>Once a list is generated, it can be reused and updated. Users can save templates and quickly modify items based on their latest needs, making it efficient for recurring grocery runs.</p>

         </div>
         <h4><b>How It Works:</b></h4>
         <div style={{fontFamily:'sans-serif'}}>
          <p> Users start by setting up a template, defining item categories, and including spaces for item names, quantities, and prices.</p>
          <p>The user inputs their required items into the template, and the tool organizes them into relevant categories.</p>
          <p>The grocery list can be printed or accessed on mobile devices for easy reference while shopping.</p>
         </div>

         <p style={{fontFamily:'sans-serif'}} >An Excel Grocery List Generator is particularly useful for families, meal planners, or businesses that need to plan shopping ahead of time. By automating the generation of grocery lists, this tool saves time, improves organization, and helps avoid overspending or forgetting items.</p>
        </div>
       </div>
    </div>
    <div className='col-md-3'>
      <Sidebar/>
    </div>
  </div>
</div>


</Layout>
  );
};

export default GroceryListGenerator;
