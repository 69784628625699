import React, { useState  , useEffect} from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Layout from '../Layout/Layout'
import { Link } from 'react-router-dom';
import Sidebar from '../Pages/Sidebar';

const ExcelFunctionGenerator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [categoryInput, setCategoryInput] = useState('');
  const [functionInput, setFunctionInput] = useState('');
  const [selectedFunction, setSelectedFunction] = useState(null);
  const [copySuccess, setCopySuccess] = useState('');
  
  // Functions array with categories and functions
  const functions = [
    {
      category: 'Math & Trigonometry',
      functions: [
        { name: 'SUM', syntax: '=SUM(number1, [number2], ...)', description: 'Adds all the numbers in a range of cells.', example: '=SUM(A1:A10)' },
        { name: 'AVERAGE', syntax: '=AVERAGE(number1, [number2], ...)', description: 'Returns the average of its arguments.', example: '=AVERAGE(A1:A10)' },
        { name: 'MIN', syntax: '=MIN(number1, [number2], ...)', description: 'Returns the smallest number in a set of values.', example: '=MIN(A1:A10)' },
        { name: 'MAX', syntax: '=MAX(number1, [number2], ...)', description: 'Returns the largest number in a set of values.', example: '=MAX(A1:A10)' },
        { name: 'SIN', syntax: '=SIN(number)', description: 'Returns the sine of a number.', example: '=SIN(PI()/2)' },
        { name: 'COS', syntax: '=COS(number)', description: 'Returns the cosine of a number.', example: '=COS(0)' },
        { name: 'TAN', syntax: '=TAN(number)', description: 'Returns the tangent of a number.', example: '=TAN(PI()/4)' },
        { name: 'SQRT', syntax: '=SQRT(number)', description: 'Returns the square root of a number.', example: '=SQRT(16)' },
        { name: 'POWER', syntax: '=POWER(number, power)', description: 'Returns the result of a number raised to a power.', example: '=POWER(2, 3)' },
        { name: 'LOG', syntax: '=LOG(number, [base])', description: 'Returns the logarithm of a number to the base you specify.', example: '=LOG(100, 10)' },
        { name: 'ABS', syntax: '=ABS(number)', description: 'Returns the absolute value of a number.', example: '=ABS(-10)' },
        { name: 'ROUND', syntax: '=ROUND(number, num_digits)', description: 'Rounds a number to a specified number of digits.', example: '=ROUND(123.456, 2)' },
        { name: 'CEILING', syntax: '=CEILING(number, significance)', description: 'Rounds a number up, away from zero, to the nearest multiple of significance.', example: '=CEILING(123.45, 0.1)' },
        { name: 'FLOOR', syntax: '=FLOOR(number, significance)', description: 'Rounds a number down, towards zero, to the nearest multiple of significance.', example: '=FLOOR(123.45, 0.1)' },
        { name: 'RAND', syntax: '=RAND()', description: 'Returns a random number between 0 and 1.', example: '=RAND()' },
        { name: 'RANDBETWEEN', syntax: '=RANDBETWEEN(bottom, top)', description: 'Returns a random integer between the numbers you specify.', example: '=RANDBETWEEN(1, 100)' },
      ],
    },
    {
      category: 'Text',
      functions: [
        { name: 'CONCATENATE', syntax: '=CONCATENATE(text1, [text2], ...)', description: 'Joins several text items into one text item.', example: '=CONCATENATE("Hello", " ", "World")' },
        { name: 'LEFT', syntax: '=LEFT(text, [num_chars])', description: 'Returns the first character or characters in a text string, based on the number of characters you specify.', example: '=LEFT("Excel", 2)' },
        { name: 'RIGHT', syntax: '=RIGHT(text, [num_chars])', description: 'Returns the last character or characters in a text string, based on the number of characters you specify.', example: '=RIGHT("Excel", 2)' },
        { name: 'MID', syntax: '=MID(text, start_num, num_chars)', description: 'Returns a specific number of characters from a text string starting at the position you specify.', example: '=MID("Excel", 2, 2)' },
        { name: 'TRIM', syntax: '=TRIM(text)', description: 'Removes all spaces from text except for single spaces between words.', example: '=TRIM(" Hello World ")' },
        { name: 'UPPER', syntax: '=UPPER(text)', description: 'Converts text to uppercase.', example: '=UPPER("excel")' },
        { name: 'LOWER', syntax: '=LOWER(text)', description: 'Converts text to lowercase.', example: '=LOWER("EXCEL")' },
        { name: 'LEN', syntax: '=LEN(text)', description: 'Returns the number of characters in a text string.', example: '=LEN("Excel")' },
        { name: 'FIND', syntax: '=FIND(find_text, within_text, [start_num])', description: 'Finds one text value within another and returns the position of the text you are looking for.', example: '=FIND("e", "Excel")' },
        { name: 'SEARCH', syntax: '=SEARCH(find_text, within_text, [start_num])', description: 'Finds one text value within another and returns the position of the text you are looking for (case-insensitive).', example: '=SEARCH("e", "Excel")' },
        { name: 'REPLACE', syntax: '=REPLACE(old_text, start_num, num_chars, new_text)', description: 'Replaces part of a text string with a different text string.', example: '=REPLACE("Hello World", 7, 5, "Universe")' },
        { name: 'SUBSTITUTE', syntax: '=SUBSTITUTE(text, old_text, new_text, [instance_num])', description: 'Substitutes new text for old text in a text string.', example: '=SUBSTITUTE("Hello Hello", "Hello", "Hi")' },
      ],
    },
    {
      category: 'Logical',
      functions: [
        { name: 'IF', syntax: '=IF(logical_test, value_if_true, value_if_false)', description: 'Returns one value if a condition is true and another value if it is false.', example: '=IF(A1>10, "Yes", "No")' },
        { name: 'AND', syntax: '=AND(logical1, [logical2], ...)', description: 'Returns TRUE if all of its arguments are TRUE.', example: '=AND(A1>10, B1<5)' },
        { name: 'OR', syntax: '=OR(logical1, [logical2], ...)', description: 'Returns TRUE if any argument is TRUE.', example: '=OR(A1>10, B1<5)' },
        { name: 'NOT', syntax: '=NOT(logical)', description: 'Reverses the logic of its argument.', example: '=NOT(A1>10)' },
        { name: 'IFERROR', syntax: '=IFERROR(value, value_if_error)', description: 'Returns a value you specify if a formula evaluates to an error; otherwise, returns the result of the formula.', example: '=IFERROR(A1/B1, "Error")' },
        { name: 'IFNA', syntax: '=IFNA(value, value_if_na)', description: 'Returns the value you specify if the expression resolves to #N/A, otherwise returns the result of the expression.', example: '=IFNA(VLOOKUP("X", A1:B10, 2, FALSE), "Not Found")' },
        { name: 'SWITCH', syntax: '=SWITCH(expression, value1, result1, [value2, result2], ...)', description: 'Evaluates an expression against a list of values and returns the result corresponding to the first matching value.', example: '=SWITCH(A1, 1, "One", 2, "Two", 3, "Three", "Not Found")' },
        { name: 'CHOOSE', syntax: '=CHOOSE(index_num, value1, [value2], ...)', description: 'Returns a value from a list of values based on an index number.', example: '=CHOOSE(2, "Red", "Blue", "Green")' },
      ],
    },
    {
      category: 'Date & Time',
      functions: [
        { name: 'TODAY', syntax: '=TODAY()', description: 'Returns the current date.', example: '=TODAY()' },
        { name: 'NOW', syntax: '=NOW()', description: 'Returns the current date and time.', example: '=NOW()' },
        { name: 'DATE', syntax: '=DATE(year, month, day)', description: 'Returns the serial number of a particular date.', example: '=DATE(2024, 1, 1)' },
        { name: 'TIME', syntax: '=TIME(hour, minute, second)', description: 'Returns the serial number of a particular time.', example: '=TIME(14, 30, 0)' },
        { name: 'DAY', syntax: '=DAY(date)', description: 'Returns the day of the month of a date.', example: '=DAY(TODAY())' },
        { name: 'MONTH', syntax: '=MONTH(date)', description: 'Returns the month of a date.', example: '=MONTH(TODAY())' },
        { name: 'YEAR', syntax: '=YEAR(date)', description: 'Returns the year of a date.', example: '=YEAR(TODAY())' },
        { name: 'WEEKDAY', syntax: '=WEEKDAY(serial_number, [return_type])', description: 'Returns the day of the week corresponding to a date.', example: '=WEEKDAY(TODAY())' },
        { name: 'NETWORKDAYS', syntax: '=NETWORKDAYS(start_date, end_date, [weekend], [holidays])', description: 'Returns the number of whole workdays between two dates.', example: '=NETWORKDAYS("01/01/2024", "31/01/2024")' },
        { name: 'WORKDAY', syntax: '=WORKDAY(start_date, days, [weekend], [holidays])', description: 'Returns the serial number of the date before or after a specified number of workdays.', example: '=WORKDAY(TODAY(), 10)' },
        { name: 'DATEDIF', syntax: '=DATEDIF(start_date, end_date, unit)', description: 'Returns the difference between two dates based on the specified unit.', example: '=DATEDIF("01/01/2024", "01/12/2024", "Y")' },
        { name: 'EOMONTH', syntax: '=EOMONTH(start_date, months)', description: 'Returns the serial number of the last day of the month before or after a specified number of months.', example: '=EOMONTH(TODAY(), 1)' },
        { name: 'EDATE', syntax: '=EDATE(start_date, months)', description: 'Returns the serial number of the date that is a specified number of months before or after a specified date.', example: '=EDATE(TODAY(), 3)' },
        { name: 'YEARFRAC', syntax: '=YEARFRAC(start_date, end_date, [basis])', description: 'Returns the year fraction between two dates based on a specified day count basis.', example: '=YEARFRAC("01/01/2024", "01/12/2024")' },
      ],
    },
    {
      category: 'Financial',
      functions: [
        { name: 'PMT', syntax: '=PMT(rate, nper, pv, [fv], [type])', description: 'Calculates the payment for a loan based on constant payments and a constant interest rate.', example: '=PMT(0.05/12, 30*12, -200000)' },
        { name: 'FV', syntax: '=FV(rate, nper, pmt, [pv], [type])', description: 'Calculates the future value of an investment based on periodic, constant payments and a constant interest rate.', example: '=FV(0.05/12, 30*12, -1000)' },
        { name: 'PV', syntax: '=PV(rate, nper, pmt, [fv], [type])', description: 'Calculates the present value of an investment based on periodic, constant payments and a constant interest rate.', example: '=PV(0.05/12, 30*12, -1000)' },
        { name: 'RATE', syntax: '=RATE(nper, pmt, pv, [fv], [type], [guess])', description: 'Calculates the interest rate of an annuity based on periodic, constant payments and a constant present value.', example: '=RATE(30*12, -1000, 200000)' },
        { name: 'NPV', syntax: '=NPV(rate, value1, [value2], ...)', description: 'Calculates the net present value of an investment based on a series of periodic cash flows and a discount rate.', example: '=NPV(0.1, -1000, 300, 300, 300, 300)' },
        { name: 'IRR', syntax: '=IRR(values, [guess])', description: 'Returns the internal rate of return for a series of cash flows.', example: '=IRR({-1000, 300, 300, 300, 300})' },
        { name: 'MIRR', syntax: '=MIRR(values, finance_rate, reinvest_rate)', description: 'Returns the modified internal rate of return for a series of cash flows.', example: '=MIRR({-1000, 300, 300, 300, 300}, 0.1, 0.12)' },
        { name: 'XIRR', syntax: '=XIRR(values, dates, [guess])', description: 'Returns the internal rate of return for a schedule of cash flows that is not necessarily periodic.', example: '=XIRR({-1000, 300, 300, 300, 300}, {"01/01/2024", "02/01/2024", "03/01/2024", "04/01/2024"})' },
        { name: 'DURATION', syntax: '=DURATION(settlement, maturity, coupon, yield, frequency, [basis])', description: 'Calculates the Macaulay duration of a security with periodic payments.', example: '=DURATION("01/01/2024", "01/01/2034", 0.05, 0.04, 2)' },
        { name: 'YIELD', syntax: '=YIELD(settlement, maturity, rate, pr, redemption, frequency, [basis])', description: 'Calculates the yield on a security that pays periodic interest.', example: '=YIELD("01/01/2024", "01/01/2034", 0.05, 100, 100, 2)' },
      ],
    },
    {
      category: 'Lookup & Reference',
      functions: [
        { name: 'VLOOKUP', syntax: '=VLOOKUP(lookup_value, table_array, col_index_num, [range_lookup])', description: 'Searches for a value in the first column of a table and returns a value in the same row from another column.', example: '=VLOOKUP("Apple", A1:B10, 2, FALSE)' },
        { name: 'HLOOKUP', syntax: '=HLOOKUP(lookup_value, table_array, row_index_num, [range_lookup])', description: 'Searches for a value in the top row of a table and returns a value in the same column from a row you specify.', example: '=HLOOKUP("Q1", A1:D4, 3, FALSE)' },
        { name: 'INDEX', syntax: '=INDEX(array, row_num, [column_num])', description: 'Returns the value of a cell in a specified row and column of a range.', example: '=INDEX(A1:C10, 2, 3)' },
        { name: 'MATCH', syntax: '=MATCH(lookup_value, lookup_array, [match_type])', description: 'Searches for a value in an array and returns the relative position of that item.', example: '=MATCH("Apple", A1:A10, 0)' },
        { name: 'OFFSET', syntax: '=OFFSET(reference, rows, cols, [height], [width])', description: 'Returns a reference to a range that is a specified number of rows and columns from a cell or range of cells.', example: '=OFFSET(A1, 2, 3, 1, 2)' },
        { name: 'CHOOSE', syntax: '=CHOOSE(index_num, value1, [value2], ...)', description: 'Returns a value from a list of values based on an index number.', example: '=CHOOSE(2, "Red", "Blue", "Green")' },
        { name: 'LOOKUP', syntax: '=LOOKUP(lookup_value, lookup_vector, [result_vector])', description: 'Looks up a value either from a single row or column or from an array.', example: '=LOOKUP(2, {1,2,3}, {10,20,30})' },
        { name: 'HYPERLINK', syntax: '=HYPERLINK(link_location, [friendly_name])', description: 'Creates a shortcut or jump that opens a document stored on a network server, an intranet, or the Internet.', example: '=HYPERLINK("http://www.example.com", "Example")' },
      ],
    },
  ];

  const handleCategoryChange = (e) => {
    setCategoryInput(e.target.value);
    setFunctionInput('');
    setSelectedFunction(null);
  };

  const handleFunctionChange = (e) => {
    setFunctionInput(e.target.value);
    const selectedCat = functions.find(cat => cat.category === categoryInput);
    if (selectedCat) {
      const selectedFunc = selectedCat.functions.find(func => func.name === e.target.value);
      setSelectedFunction(selectedFunc);
    }
  };

  // const handleGenerate = () => {
  //   const selectedCat = functions.find(cat => cat.category === categoryInput);
  //   if (selectedCat) {
  //     const selectedFunc = selectedCat.functions.find(func => func.name === functionInput);
  //     setSelectedFunction(selectedFunc);
  //   }
  // };

  const handleCopy = () => {
    setCopySuccess('Copied!');
    setTimeout(() => setCopySuccess(''), 2000);
  };



  return (
    <Layout 
pageTitle="Excel Function Generator | ExcelsGenerator"
metaDescription="Easily generate and customize Excel functions with our Excel Function Generator. Create functions for various data manipulations and download them in Excel format."
metaKeywords="Excel function generator, generate Excel functions, custom Excel functions, Excel data manipulation tool"
canonicalUrl="https://excelsgenerator.com/excel-function-generator"
robotsContent="index, follow"
ogTitle="Excel Function Generator | ExcelsGenerator"
ogDescription="Easily generate and customize Excel functions with our Excel Function Generator. Create functions for various data manipulations and download them in Excel format."
ogUrl="https://excelsgenerator.com/excel-function-generator"
ogImage="https://excelsgenerator.com/img/logo.png"
schemaData={{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "url": "https://excelsgenerator.com/excel-function-generator",
  "name": "Excel Function Generator",
  "description": "Easily generate and customize Excel functions with our Excel Function Generator. Create functions for various data manipulations and download them in Excel format.",
  "publisher": {
    "@type": "Organization",
    "name": "ExcelsGenerator",
    "logo": {
      "@type": "ImageObject",
      "url": "https://excelsgenerator.com/img/logo.png"
    }
  }
}}

    
    >
        <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-1'></div>
          <div className='col-md-8 main-section'>
            <div className='container'>
            <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><Link to="/" style={{textDecoration:'none', color:'black'}}>Home</Link></li>
        <li className="breadcrumb-item"><Link to="/all-tools" style={{textDecoration:'none' , color:'black'}}>All Tools</Link></li>
        <li className="breadcrumb-item active" aria-current="page">Excel Function Generator</li>
      </ol>
        </nav>
        <h1 className='text-center'>
        Excel Function Generator
        </h1>
        <div className='container text-center'>
          <div className='row'>
            <div className='col-md-12'>
            <label htmlFor='Category' className='form-label label' >
            <b>Category:</b>
            <span className='text-danger' >*</span>
             </label>
          <select id='input' className='form-control' value={categoryInput} onChange={handleCategoryChange}>
            <option value="">Select Category</option>
            {functions.map(cat => (
              <option key={cat.category} value={cat.category}>
                {cat.category}
              </option>
            ))}
          </select>
            </div>
          </div>

          {categoryInput && (
        <div className='row input-row'>
        <div className='col-md-12'>
        <label htmlFor='function' className='form-label label' >
        <b>Function: </b>
        <span className='text-danger'>*</span>
             </label>
            <select id='date' className='form-control' value={functionInput} onChange={handleFunctionChange}>
              <option value="">Select Function</option>
              {functions.find(cat => cat.category === categoryInput)?.functions.map(func => (
                <option key={func.name} value={func.name}>
                  {func.name}
                </option>
              ))}
            </select>
        </div>
        </div>
      )}
        </div>
            </div>
            {selectedFunction && (
  <div className='table-responsive' style={{marginTop:'3vh'}}>
    <h2>Function: {selectedFunction.name}</h2>
    <table className='table' border="1" cellPadding="10" cellSpacing="0">
      <tbody>
        <tr>
          <td><strong>Syntax:</strong></td>
          <td>{selectedFunction.syntax}</td>
        </tr>
        <tr>
          <td><strong>Description:</strong></td>
          <td>{selectedFunction.description}</td>
        </tr>
        <tr>
          <td><strong>Example:</strong></td>
          <td>{selectedFunction.example}</td>
        </tr>
        <tr>
          <td colSpan="2">
            <CopyToClipboard text={selectedFunction.syntax} onCopy={handleCopy}>
              <button className='btn btn-success' >Copy Function</button>
            </CopyToClipboard>
            {copySuccess && <span style={{ color: 'green' }}>{copySuccess}</span>}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
)}

              <div className='container'style={{marginTop:'4vh'}} >
                <div className='row'>
                  <hr></hr>
                  <h3>About Excel Function Generator</h3>
                  <hr></hr>
                  <p style={{fontFamily:'sans-serif'}}>An Excel Function Generator is a tool designed to help users easily create and understand various Excel functions without needing to memorize syntax or manually input formulas. This tool typically offers a user-friendly interface where users can select or input their desired function, customize parameters (such as cell references, numbers, or text), and instantly generate the corresponding Excel formula.</p>
                  <h4><b>Key Features of an Excel Function Generator:</b></h4>
                  <p style={{fontFamily:'sans-serif'}} >
                  It supports all types of Excel functions, from basic ones like SUM, AVERAGE, and COUNT, to more advanced functions like VLOOKUP, IF, INDEX, and MATCH.
                  </p>
                  <p style={{fontFamily:'sans-serif'}}>It provides the correct syntax for each function along with a brief description to explain how the function works.</p>
                  <p style={{fontFamily:'sans-serif'}} >It often includes examples demonstrating how each function can be used in real-world scenarios.</p>
                  <p style={{fontFamily:'sans-serif'}} >Users can generate a function and easily copy it to their clipboard for use directly in Excel.</p>
                  <p style={{fontFamily:'sans-serif'}} >Users can select or input parameters, such as cell ranges or values, to get a customized function output.</p>
                  <h4><b>Benefits of Using an Excel Function Generator</b></h4>
                  <p style={{fontFamily:'sans-serif'}} >It eliminates the need for manually typing complex formulas and reduces the time spent looking up syntax or troubleshooting errors.</p>
                  <p style={{fontFamily:'sans-serif'}} >By generating error-free formulas and previewing results, users can be confident that their calculations are accurate.</p>

                  <p style={{fontFamily:'sans-serif'}} >Users can create highly customized functions that suit their specific needs, such as financial models, data analysis, or project management calculations.</p>

                  <p style={{fontFamily:'sans-serif'}} >Professionals working with large datasets or complex Excel workbooks can boost their productivity by quickly generating and applying formulas, ensuring faster workflows.</p>

                  <h5><b>Conclusion:</b></h5>
                  <p style={{fontFamily:'sans-serif'}} >An Excel Function Generator is an indispensable tool for anyone looking to leverage the power of Excel formulas without having to master their syntax and intricacies. Whether for business, education, finance, or data analysis, the tool helps users work more efficiently, learn faster, and ensure error-free calculations.</p>
                </div>
              </div>
          </div>
          <div className='col-md-3'>
            <Sidebar/>
          </div>
        </div>
    </div>
    </Layout>
  )
}

export default ExcelFunctionGenerator
