import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { Helmet } from 'react-helmet';

const Layout = ({
  pageTitle,
  metaDescription,
  metaKeywords,
  schemaData,
  robotsContent,
  canonicalUrl,
  ogTitle,
  ogDescription,
  ogUrl,
  ogImage,
  children,
}) => {


  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />

        {/* Dynamic Schema.org data */}
        {schemaData && (
          <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
        )}
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        {/* Robots Tag */}
        {robotsContent && <meta name="robots" content={robotsContent} />}

        {/* Open Graph data */}
        {ogTitle && <meta property="og:title" content={ogTitle} />}
        {ogDescription && <meta property="og:description" content={ogDescription} />}
        {ogUrl && <meta property="og:url" content={ogUrl} />}
        {ogImage && <meta property="og:image" content={ogImage} />}
      </Helmet>
      <Header /> {/* Rendering the Header component */}
      <main>
        {children} {/* Rendering the child components passed to Layout */}
      </main>
      <Footer /> {/* Rendering the Footer component */}
    </>
  );
};

export default Layout;
