// src/BingoCardGenerator.js

import React, { useState ,useEffect } from 'react';
import Layout from '../Layout/Layout';
import { Link } from 'react-router-dom';
import Sidebar from '../Pages/Sidebar';
// import * as XLSX from 'xlsx';

const BingoCardGenerator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [bingoCard, setBingoCard] = useState([]);
  const [rows, setRows] = useState(5);
  const [columns, setColumns] = useState(5);
  const [customHeaders, setCustomHeaders] = useState(['B', 'I', 'N', 'G', 'O']);

  const generateBingoCard = () => {
    const card = [];
    const colRanges = {
      B: { min: 1, max: 15 },
      I: { min: 16, max: 30 },
      N: { min: 31, max: 45 },
      G: { min: 46, max: 60 },
      O: { min: 61, max: 75 },
    };

    const columnsList = Object.keys(colRanges).slice(0, columns); // Limit columns based on user input

    for (let i = 0; i < rows; i++) {
      const row = [];
      columnsList.forEach((col, index) => {
        const range = colRanges[col];
        const count = (col === 'N' && rows % 2 !== 0 && i === Math.floor(rows / 2)) ? 1 : 5; // Handle 'FREE' space for odd rows
        const numbers = getRandomNumbers(range.min, range.max, count);
        row.push(col === 'N' && i === Math.floor(rows / 2) ? 'FREE' : numbers[i % count]);
      });
      card.push(row);
    }

    setBingoCard(card);
  };

  const getRandomNumbers = (min, max, count) => {
    const numbers = new Set();
    while (numbers.size < count) {
      const num = Math.floor(Math.random() * (max - min + 1)) + min;
      numbers.add(num);
    }
    return Array.from(numbers);
  };

  // const handleExportToExcel = () => {
  //   const data = bingoCard.map((row) => {
  //     const rowData = {};
  //     columns.forEach((_, index) => {
  //       rowData[customHeaders[index] || `Col${index + 1}`] = row[index];
  //     });
  //     return rowData;
  //   });

  //   const worksheet = XLSX.utils.json_to_sheet(data);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Bingo Card');

  //   // Export to Excel
  //   XLSX.writeFile(workbook, 'Bingo_Card.xlsx');
  // };

  const handleHeaderChange = (index, value) => {
    const newHeaders = [...customHeaders];
    newHeaders[index] = value;
    setCustomHeaders(newHeaders);
  };

  return (

    <Layout 
    pageTitle="Excel Bingo Card Generator | ExcelsGenerator"
metaDescription="Create customizable bingo cards in Excel format with our Excel Bingo Card Generator. Perfect for games, classrooms, or events."
metaKeywords="Excel bingo card generator, custom bingo card, Excel bingo, generate bingo cards"
canonicalUrl="https://excelsgenerator.com/excel-bingo-card-generator"
robotsContent="index, follow"
ogTitle="Excel Bingo Card Generator | ExcelsGenerator"
ogDescription="Create customizable bingo cards in Excel format with our Excel Bingo Card Generator. Perfect for games, classrooms, or events."
ogUrl="https://excelsgenerator.com/excel-bingo-card-generator"
ogImage="https://excelsgenerator.com/img/logo.png"

schemaData={{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "url": "https://excelsgenerator.com/excel-bingo-card-generator",
  "name": "Excel Bingo Card Generator",
  "description": "Create customizable bingo cards in Excel format with our Excel Bingo Card Generator. Perfect for games, classrooms, or events.",
  "publisher": {
    "@type": "Organization",
    "name": "ExcelsGenerator",
    "logo": {
      "@type": "ImageObject",
      "url": "https://excelsgenerator.com/img/logo.png"
    }
  }
}}

    >
    <div className='container-fluid'>
       <div className='row'>
         <div className='col-md-1'></div>
         <div className='col-md-8 main-section'>
           <div className='container'>
           <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/all-tools" style={{ textDecoration: 'none', color: 'black' }}>
                      All Tools
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Excel Bingo Card Generator
                  </li>
                </ol>
                </nav>
                <div className='container text-center'>
                <h1 className='text-center'>Excel Bingo Card Generator</h1>
                  <div className='row'>
                    <div className='col-md-12'>
                    <input
                    type="number"
                    value={rows}
                    id='input'
                    className='form-control'
                    onChange={(e) => setRows(Number(e.target.value))}
                    min="1"
                    max="10"
                    placeholder="Rows"
                    
                  />
                    </div>
                  </div>

                  <div className='row input-row'>
                    <div className='col-md-12'>
                    <input
                    type="number"
                    value={columns}
                    id='date'
                    className='form-control'
                    onChange={(e) => setColumns(Number(e.target.value))}
                    min="1"
                    max="5"
                    placeholder="Columns"
                  />
                    </div>
                  </div>

                  <div className='row input-row'>
                    <div className='col-md-12'>
                    {Array.from({ length: columns }, (_, index) => (
                    <input
                      key={index}
                      type="text"
                      id='input'
                      className='form-control'
                      value={customHeaders[index] || ''}
                      onChange={(e) => handleHeaderChange(index, e.target.value)}
                      placeholder={`Header ${index + 1}`}
                    />
                    ))}
                    </div>
                  </div>

                  <div className='row input-row'>
                    <div className='col-md-12'>
                    <button onClick={generateBingoCard} className='cta-button btn btn-lg mt-4'>Generate Card</button>
                    </div>
                  </div>
                </div>
                {bingoCard.length > 0 && (
        <div className='table-responsive' style={{marginTop:'4vh'}}>
          <h4>Your Bingo Card:</h4>
          <table className='table'>
            <thead>
              <tr>
                {Array.from({ length: columns }, (_, index) => (
                  <th key={index}>{customHeaders[index] || `Col${index + 1}`}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {bingoCard.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((num, colIndex) => (
                    <td key={colIndex} style={num === 'FREE' ? styles.freeSpace : {}}>{num}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
           </div>

           <div className='container' style={{marginTop:'4vh'}}>
            <div className='row'>
              <hr></hr>
              <h3>About Excel Bingo Card Generator</h3>
              <hr></hr>
              <p style={{fontFamily:'sans-serif'}}>Bingo is a fun and popular game that can be played at parties, events, classrooms, or even online gatherings. Creating Bingo cards manually can be tedious, but with Excel, you can automate the process and generate multiple Bingo cards quickly and easily. In this guide, we'll explore how to create a Bingo Card Generator in Excel to produce randomized Bingo cards for your games.</p>
              <h4><b>Why Use Excel for Bingo Cards?</b></h4>
              <p style={{fontFamily:'sans-serif'}}>Excel is a versatile tool that allows you to generate, customize, and manage Bingo cards without the need for complex software. The benefits of using Excel for generating Bingo cards.</p>

              <h4><b>Practical Applications of the Bingo Card Generator</b></h4>
              <div>
              <p>Teachers can create educational Bingo cards with math problems, vocabulary words, or historical facts.</p>
              <p>Event organizers can use custom Bingo cards for games at parties, family gatherings, or office events.</p>
              <p>As more events move online, Excel-generated Bingo cards can be shared with participants in virtual games.</p>
              </div>
              <h4><b>Advanced Tips for Excel Bingo Card Generator</b></h4>
              <p style={{fontFamily:'sans-serif'}}>Customizing the Bingo Content: Instead of numbers, you can generate random words, phrases, or images. For example, create a Holiday-themed Bingo card with festive words or a classroom Bingo with vocabulary terms.</p>
              <p style={{fontFamily:'sans-serif'}}>Using Macros: For larger events, you can create a macro in Excel to generate hundreds of unique Bingo cards at the click of a button. This is particularly useful for bigger Bingo games with more participants.</p>
              <p style={{fontFamily:'sans-serif'}}>Conditional Formatting: To enhance the user experience, you can apply conditional formatting to automatically highlight cells when numbers are called during the game.</p>

              <p style={{fontFamily:'sans-serif'}}>Creating a Bingo Card Generator in Excel is a simple and efficient way to produce customized Bingo cards for various events, games, and educational purposes. By using Excel’s built-in functions like RANDBETWEEN() and leveraging its formatting capabilities, you can generate random, unique Bingo cards in just a few steps. Whether it’s for a party or a classroom activity, Excel provides a flexible and powerful platform to generate the perfect Bingo cards.</p>
            </div>
           </div>
         </div>
         <div className='col-md-3'>
          <Sidebar/>
         </div>
       </div>
    </div>
    </Layout>
    
  );
};

const styles = {
  freeSpace: {
    backgroundColor: '#FFD700', // Highlight the 'FREE' space
    textAlign: 'center',
  },
  
};

export default BingoCardGenerator;
