import { Routes , Route } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home';
import AboutUs from './Pages/About'; 
import Contact from './Pages/Contact';
import Tool from './Pages/Tool';
import ChartGenerator from './Tool/ChartGenerator';
import DateGenerator from './Tool/DateGenerator';
import ExcelCalculator from './Tool/ExcelCalculator';
import ExcelFormulaGenerator from './Tool/ExcelFormulaGenerator';
import ExcelFunctionGenerator from './Tool/ExcelFunctionGenerator';
import InvoiceNumberGenerator from './Tool/InvoiceNumberGenerator'
import PasswordGenerator from './Tool/PasswordGenerator';
import QRCodeGenerator from './Tool/QRCodeGenerator'
import BudgetCalculator from './Tool/BudgeCalculator';
import BillGenerator from './Tool/BillGenerator';
import BingoCardGenerator from './Tool/BingoCardGenerator';
import EpochTimeGenerator from './Tool/EpochTimeGenerator'
import GoogleSheetsFormulaGenerator from './Tool/GoogleSheetsFormulaGenerator';
import GroceryListGenerator from './Tool/GroceryListGenerator';
import SkuGenerator from './Tool/SkuGenerator';
import RandomDataGenerator from './Tool/RandomDataGenerator';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsAndConditions from './Pages/TermsAndConditions';
import Disclaimer from './Pages/Disclaimer';


function App() {
  return (
    <>
    <Routes>
      <Route path='/' element={<Home/>}></Route>
      <Route path='/about-us' element={<AboutUs/>}></Route>
      <Route path='/contact-us' element={<Contact/>}></Route>
      <Route path='/tools' element={<Tool/>}></Route>
      <Route path='/privacy-policy' element={<PrivacyPolicy/>}></Route>
      <Route path='/terms-and-conditions' element={<TermsAndConditions/>}></Route>
      <Route path='/disclaimer' element={<Disclaimer/>} /><Route/>
      <Route path='/excel-chart-generator' element={<ChartGenerator/>}></Route>
      <Route path='/excel-random-date-generator' element={<DateGenerator/>}></Route>
      <Route path='/excel-random-number-generator' element={<ExcelCalculator/>}></Route>
      <Route path='/excel-formula-generator' element={<ExcelFormulaGenerator/>}></Route>
      <Route path='/excel-function-generator' element={<ExcelFunctionGenerator/>}></Route>
      <Route path='/excel-invoice-number-generator' element={<InvoiceNumberGenerator/>}></Route>
      <Route path='/excel-password-generator' element={<PasswordGenerator/>}></Route>
      <Route path='/excel-qr-code-generator' element={<QRCodeGenerator/>}></Route>
      <Route path='/excel-budget-calculator' element={<BudgetCalculator/>}></Route>
      <Route path='/excel-bill-generator' element={<BillGenerator/>}></Route>
      <Route path='/excel-bingo-card-generator' element={<BingoCardGenerator/>}></Route>
      <Route path='/excel-epoch-time-generator' element={<EpochTimeGenerator/>}></Route>
      <Route path='/excel-grocery-list-generator' element={<GroceryListGenerator/>}></Route>
      <Route path='/google-sheets-formula-generator' element={<GoogleSheetsFormulaGenerator/>}></Route>
      <Route path='/excel-sku-generator' element={<SkuGenerator/>}></Route>
      <Route path='/excel-random-data-generator' element={<RandomDataGenerator/>}></Route>
    </Routes>

    </>
  );
}

export default App;
